.BucketListHolder-content {
  // width: 300px;
  // height: auto;
  // display: flex;
  // display: grid;
  // flex-wrap: wrap;
  //  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  //  grid-gap: 34px;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  grid-row-gap: 35px;
}
.regular .BucketListHolder-content {
  grid-gap: 10px;
  grid-row-gap: 35px;
}
.bucketHeader {
  margin-bottom: 34px;
}
.filterHolder {
  margin-top: -24px;
}
.buttonpadding-content {
  padding-top: 6px;
  padding-bottom: 6px;
}
.listcard-imgcontent {
  padding-right: 18px;
}
.dark .listcard-imgcontent {
  filter: invert(100%) sepia(5%) saturate(72%) hue-rotate(59deg)
    brightness(116%) contrast(78%);
}
.dark .compact-imgcontent {
  filter: invert(100%) sepia(5%) saturate(72%) hue-rotate(59deg)
    brightness(116%) contrast(78%);
}
.dark .next-icons {
  filter: invert(96%) sepia(2%) saturate(2722%) hue-rotate(186deg)
    brightness(94%) contrast(82%);
}
.mybuckets-content {
  font-family: "Objectivity-Medium";
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  line-height: 42px;
  letter-spacing: -1.4px;
  text-align: left;
  color: #262626;
}
.createbutton-context {
  font-family: "HelveticaNeue";
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: normal;
  color: #fdfdfd;
}
.totalbucket-content {
  font-family: "HelveticaNeue";
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  margin-top: 15px;
  display: flex;
  color: #707070;
}
.bucket-imgcontent {
  margin-top: -3px;
  margin-right: 10px;
}
.text-background {
  font-family: "HelveticaNeue";
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  padding: 4px 10px 4px 10px;
  text-align: left;
  // display: inline;
  border-radius: 20px;
  color: #fff;
  background-color: #000;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20ch;
  // display: -webkit-inline-box;
  // -webkit-line-clamp: 1;
  // -webkit-box-orient: vertical;
  // overflow: hidden;
}
.cardlist-content {
  padding: 8px;
}
.share-content {
  font-family: "HelveticaNeue";
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  // padding-top: 4px;
  color: #000;
}
.bookmarkssubfilesupload-section .buttonpadding-content {
  margin-right: 0;
}
.bookmarksGrid-wholesection .bookmarkssubfilesupload-section .lastfile-section {
  padding-top: 0;
}
.shareright-content {
  font-family: "HelveticaNeue";
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: right;
  margin-top: 4px;
  color: #000;
}
.cardTags-content {
  width: 74%;
}
.icon-imgcontent {
  margin-right: 18px;
}
.sorttext-content {
  font-family: "HelveticaNeue";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  line-height: 29px;
  letter-spacing: normal;
  text-align: left;
  margin-right: 11px;
  color: #707070;
}
.createdtext-content {
  font-family: "HelveticaNeue";
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  // line-height: 26px;
  letter-spacing: normal;
  text-align: left;
  // padding-right: 14px;
  color: #262626;
}
.share-content {
  // max-height: 210px;
  // overflow-y: scroll;
  // padding-right: 10px;
}
.share-content::-webkit-scrollbar {
  width: 10px;
  height: 100px;
}
.share-content::-webkit-scrollbar-track {
  background-color: transparent;
}
.share-content::-webkit-scrollbar-thumb {
  background-color: #c6c6c6;
  border-radius: 8px;
}
.createdtext-content.share-popup {
  padding-right: 8px;
}
.bucketsinside {
  margin-top: 7px;
}
.imagesmall-content {
  width: unset;
  height: 166px;
  margin: 0 auto;
  margin-top: 8px;
  border-radius: 8px;
}
.gradient-wholebackground {
  height: 75px;
  background: #74cffb;
  background-position: center !important;
  background-size: cover !important;
}
.profile-imgcontent {
  display: flex;
  margin-bottom: 2px;
}
.profile-imgcontent img {
  width: 25px;
  height: 25px;
}
// .profile-imgcontent img:nth-child(2) {
//   margin-left: -9px;
// }
.profile-imgcontent img:nth-child(3) {
  margin-left: -12px;
}
.profile-imgcontent img:nth-child(4) {
  margin-left: -13px;
}
.profile-imgcontent img:nth-child(5) {
  margin-left: -13px;
}
.display-content {
  display: flex;
}
.display-rightcontent {
  margin-left: auto;
}
.profile-displaycontent {
  // width: 25%;
  margin-top: 10px;
  margin-left: auto;
}
.tagicon-imgcontent {
  margin-top: 3px;
}
.tagsname-textcontent {
  font-family: "HelveticaNeue";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 16px;
  letter-spacing: normal;
  text-align: left;
  background-color: #eadbff;
  color: #262626;
}
.tagsnamefile-textcontent {
  font-family: "HelveticaNeue";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 16px;
  letter-spacing: normal;
  text-align: left;
  padding-bottom: 2px;
  background-color: #eadbff;
  // max-width: 85px;
  // display: -webkit-inline-box;
  // -webkit-line-clamp: 1;
  // -webkit-box-orient: vertical;
  // overflow: hidden;
  // background-color: #eadbff;
  // color: #262626;
}
.tagsnamefile-truncated {
  font-family: "HelveticaNeue";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 16px;
  letter-spacing: normal;
  text-align: left;
  padding-bottom: 4px;
  // max-width: 85px;
  display: -webkit-inline-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  background-color: #eadbff;
  color: #262626;
}
.fileimagespace-content {
  width: unset !important;
  height: 166px;
  margin: 0 auto;
  object-fit: contain;
  border-radius: 10px;
}
.subBuckets-whole {
  .subBucketsList .BucketListHolder-content .bucketSingle {
    width: 190px;
    height: 150px;
    z-index: 10;
    position: relative;
    border: solid 1px #e5e5e5;
    background-color: #e8e8e8;
  }
  .display-content {
    display: flex;
  }
  .filterHolder {
    margin-top: unset;
  }
  .subbucketsbg-content {
    padding: 1px 12px;
  }
  .BucketListHolder-content .bucketSingle button {
    background: transparent;
  }
  .subBucketsList {
    margin-top: 26px;
  }
  .subBucketsHolder {
    padding-top: 26px;
  }
  .subbucket-displaycontent {
    display: flex;
  }
  .subbucket-endcard {
    margin-left: auto;
  }
  .subBucket-content {
    font-family: "HelveticaNeue";
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #8276b4;
  }
  .uxtext-content {
    color: #707070;
  }
  .bucketName-content {
    font-family: "Objectivity";
    font-size: 22px;
    font-weight: bold;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -1.4px;
    text-align: left;
    margin-bottom: 12px;
    margin-top: 18px;
    color: #262626;
    font-family: "Objectivity";
  }
  .bucketActions {
    padding-left: 24px;
  }
  .trashicon-content {
    padding-left: 30px;
  }
  .subbucketstext-content {
    font-family: "HelveticaNeue";
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #262626;
  }
  .cardssubBuckets-content {
    font-family: "HelveticaNeue";
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #262626;
  }
}
.social-textcontent {
  font-family: "HelveticaNeue";
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  text-align: left;
  color: #262626;
  // width: 180px !important;
  display: -webkit-inline-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  vertical-align: middle;
  // padding-right: 15px;
}
.cardContent h6 {
  display: none;
}
.carddetail .cardContent h6 {
  display: block;
}

.cardContent {
  font-family: "HelveticaNeue";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 22px;
  letter-spacing: normal;
  text-align: left;
  width: 100%;
  color: #262626;
  width: 100%;
  // -webkit-line-clamp: 5;
  // -webkit-box-orient: vertical;
  overflow: hidden;
  // display: -webkit-inline-box;
}
.cardlist-content {
  // padding-left: 14px;
  // padding-right: 14px;
  padding: 8px;
}
.updatedtext-content {
  font-family: "HelveticaNeue";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #484848;
}
// #grid {
//     display: grid;
//     grid-template-columns: repeat(auto-fill, minmax(365px, 1fr));
//     grid-gap: 34px;
//   }
//   #grid > .card-wholecontent {
//     width: 365px;
//     height: 300px;
// padding: 13px 17px;
// border-radius: 10px;
// margin-left: 34px;
// border: solid 1px #e5e5e5;
// background-color: #fff;
// box-shadow: 0 6px 9px 0 rgba(0, 0, 0, 0.16);
// }
.dark {
  .compact-view-bucket {
    filter: invert(100%) sepia(5%) saturate(72%) hue-rotate(59deg)
      brightness(116%) contrast(78%);
  }
  .sorttext-content {
    color: #acacac;
  }
  .bucket-view-icon {
    filter: invert(86%) sepia(0%) saturate(5833%) hue-rotate(88deg)
      brightness(103%) contrast(99%);
  }
  .dark-mode-icon {
    filter: invert(79%) sepia(9%) saturate(182%) hue-rotate(175deg)
      brightness(94%) contrast(89%);
  }
  .dark .bucket-view {
    filter: invert(86%) sepia(0%) saturate(5833%) hue-rotate(88deg)
      brightness(103%) contrast(99%);
  }

  .popup-subbucket {
    z-index: 999;
  }
}
.carddetail {
  .bucketname-textcontent {
    font-family: "HelveticaNeue";
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #8276b4;
  }
  .trash-icon {
    height: 22px;
  }
  .next-icons {
    width: 24px;
    height: 24px;
  }
  .inner-logo {
    // margin-left: 8px;
    width: 34px;
    height: 34px;
    // margin-top: -4px;
    display: flex;
    align-items: center;
    border-radius: 50px;
  }
  .topinner-logo {
    width: 34px;
    height: 34px;
    margin-right: 16px;
    border-radius: 50px;
  }
  .topinner-logodetai {
    width: 34px;
    height: 34px;
    // margin-right: 12px;
    border-radius: 50px;
  }
  .bucketnametitle-content {
    font-size: 22px;
    font-weight: bold;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -1.4px;
    text-align: left;
    text-transform: capitalize;
    margin-bottom: 12px;
    margin-top: 18px;
    color: #262626;
    font-family: "Objectivity";
  }
  .carddetail-endcard {
    // margin-top: 40px;
    margin-left: auto;
    display: flex;
  }
  .sharedAction {
    margin-right: 30px;
  }
  .publicytext-content {
    font-family: "HelveticaNeue";
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
  }
  .actionsHolder {
    padding-top: 18px;
  }
  .actionicon-content {
    font-family: "HelveticaNeue";
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: #8276b4;
  }
  .bucketActions {
    // padding-left: 24px;
  }
  .cardHeaderContent {
    padding-top: 18px;
  }
  .cardHeaderContents {
    // padding-top: 10px;
  }
  .secondsection-content {
    display: flex;
    padding-top: 40px;
  }
  .buckettitle-textcontent {
    font-family: 'Segoe UI';
    font-size: 28px;
    font-weight: 500;
    font-style: normal;
    line-height: 28px;
    letter-spacing: normal;
    text-align: left;
    padding-left: 6px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    color: #262626;
  }
  .iconsfilesspace-content {
    // align-self: baseline;
    display: flex;
    align-items: center;
  }
  .titlebucket-content {
    margin-bottom: 16px;
  }
  .createdbucket-content {
    font-family: "HelveticaNeue";
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 1.65;
    letter-spacing: normal;
    text-align: left;
    padding-right: 20px;
    color: #262626;
  }
  .cardheader-text content {
    margin-bottom: 20px;
  }
  // .secondsection-content{
  //     padding-top: 24px;
  // }
  .cardtextwhole-content {
    padding-right: 1px;
  }
  .headingtext-content {
    font-family: "HelveticaNeue";
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.83;
    letter-spacing: normal;
    text-align: left;
    padding-bottom: 10px;
    color: #262626;
  }
  .savetext-button {
    font-family: "HelveticaNeue";
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    padding: 5px 25px;
  }
  .canceltext-content {
    font-family: "HelveticaNeue";
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: #8276b4;
  }
  .tagname-textcontent {
    font-family: HelveticaNeue;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 26px;
    letter-spacing: normal;
    text-align: left;
    padding-right: 5px;
    color: #262626;
  }
  .separatetext-content {
    font-family: HelveticaNeue;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    margin-top: 10px;
    color: #707070;
  }
}
.viewGrid:focus .show-card {
  opacity: 0;
}
.dark .compact-view-lite {
  filter: invert(86%) sepia(0%) saturate(5833%) hue-rotate(88deg)
    brightness(103%) contrast(99%);
}
.dark .compact-view-dark {
  filter: invert(86%) sepia(0%) saturate(5833%) hue-rotate(88deg)
    brightness(103%) contrast(99%);
}
// .profile-displaycontent{
//     flex: none;
// }

.popup-upload {
  // width: 60%;
  // height: 90vh;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  // border-radius: 5px;
  margin: auto;
  position: relative;
  top: 2%;
  height: 488px;
  overflow: hidden;
  .left {
    border-radius: 15px;
  }
  .react-tabs {
    ul {
      border-bottom: 1px solid #dedede;
    }
  }
  .icon-upload {
    .grid {
      .customizeicon-bucket {
        height: 40px;
        width: 40px;
        padding: 7px;
      }
    }
  }
  .change-histoty {
    span {
      width: 20px;
      height: 20px;
      background-color: #24b9ff;
      margin-left: 7px;
    }
    .close-history {
      margin-left: 7px;
    }
  }

  .recent-color {
    margin: auto 0;
    vertical-align: middle;
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      color: #262626;
      opacity: 0.5;
    }
    div {
      width: 20px;
      height: 20px;
      border-radius: 50px;
      display: inline-block;
      margin-left: 3px;
    }
  }
  .color-upload .colors {
    div {
      overflow-y: unset !important;
      padding: unset !important;
      border-radius: 8px;
      box-shadow: unset !important;
      // margin-right: 15px !important;
      //   background-color: unset !important;
    }
  }

  .react-tabs__tab {
    display: inline-flex;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    // border: 1px solid #D8D8D8;
    padding: 7px 15px;
    color: #262626;
    width: 25%;
    justify-content: center;
    margin-right: 0px !important;

    img {
      padding-right: 9px;
      width: 28px;
    }
  }
  h3 {
    font-family: "Inter-Bold";
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    padding-bottom: 20px;
    border-radius: 15px 0px 0px 0px;
  }
  .share-count {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    margin: auto 0;
  }
  .react-tabs__tab-list {
    border-bottom: 1px solid #aaa;

    li {
      font-family: HelveticaNeue;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.25;
      letter-spacing: normal;
      text-align: left;
      color: #262626;
    }
    .EmojiPickerReact {
      width: unset;
    }
  }
}
.customizepopup-content {
  z-index: 2;
  margin-left: -24px;
  margin-top: -52px;
  display: flex;
}
.EmojiPickerReact.epr-main {
  width: auto !important;
  height: 59vh !important;
}
.epr-header {
  display: none;
}
.EmojiPickerReact li.epr-emoji-category > .epr-emoji-category-label {
  display: none !important;
}
.Flex.epr-preview.FlexRow {
  display: none;
}
.epr-hidden-on-search {
  display: none !important;
}
aside.EmojiPickerReact.epr-main {
  border: none;
  border-width: 0 !important;
}
.dark .popup-upload .color-upload .colors div {
  background-color: #0c2556;
  border-radius: unset;
}
// .EmojiPickerReact .epr-body::-webkit-scrollbar-track {
//     background: transparent;
//     border-radius: 10px;
//   }
//   .EmojiPickerReact .epr-body::-webkit-scrollbar-thumb {
//     background: #C8C7C8;
//     height: 80px;
//     border-radius: 10px;
//   }
//   .EmojiPickerReact .epr-body::-webkit-scrollbar {
//     width: 8px;
//     height: 30px;
//     border-radius: 10px !important;

//   }
.sketch-picker {
  width: auto !important;
  display: flex;
  flex-direction: row-reverse;
  box-shadow: none !important;
  height: 200px;
}

#rc-editable-input-1,
#rc-editable-input-3,
#rc-editable-input-2,
#rc-editable-input-4,
#rc-editable-input-5 {
  display: none;
}
.flexbox-fix label {
  display: none !important;
}
.flexbox-fix > div {
  margin: 0 !important;
}
.flexbox-fix > div > span > div {
  border-radius: 50px !important;
}
.sketch-picker .flexbox-fix:nth-child(3) {
  display: none !important;
  grid-template-columns: 66.667% 33.334% !important;
}
.flexbox-fix {
  display: grid !important;
  display: grid !important;
  grid-template-columns: 66.667% 33.334% !important;
  padding: 10px !important;
}
.sketch-picker .flexbox-fix:nth-child(2) {
  display: none !important;
}
.save-name {
  padding: 14px 20px;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  width: 60%;
  border-radius: 0 0 0 15px;
}
.popup-upload {
  border-radius: 15px;
  .react-tabs__tab-panel {
    padding: 15px 20px 0 20px;
    // overflow-y: scroll;
    padding-bottom: 30px;
  }
  .icon-upload,
  .color-upload {
    height: 260px;
    // padding: 10px 10px 10px 0;
    overflow-y: scroll;
  }
  .icon-upload,
  .bgimage-upload {
    height: 299px;
    // padding: 10px 10px 10px 0;
    overflow-y: scroll;
  }
  .applysettings-content {
    display: flex;
    align-items: center;
  }
  .color-upload {
    height: 260px;
    padding: 10px 0px 0px 0;
    overflow-y: scroll;
  }
  .icon-upload::-webkit-scrollbar-track,
  .color-upload::-webkit-scrollbar-track,
  .bgimage-upload::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }
  .icon-upload::-webkit-scrollbar-thumb,
  .color-upload::-webkit-scrollbar-thumb,
  .bgimage-upload::-webkit-scrollbar-thumb {
    background: #c8c7c8;
    height: 80px;
    border-radius: 10px;
  }
  .icon-upload::-webkit-scrollbar,
  .color-upload::-webkit-scrollbar,
  .bgimage-upload::-webkit-scrollbar {
    width: 8px;
    height: 30px;
    border-radius: 10px !important;
  }
  .react-tabs__tab-panel::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }
  .react-tabs__tab-panel::-webkit-scrollbar-thumb {
    background: #c8c7c8;
    height: 80px;
    border-radius: 10px;
  }
  .react-tabs__tab-panel::-webkit-scrollbar {
    width: 8px;
    height: 30px;
    border-radius: 10px !important;
  }
}
.patterns-upload div {
  margin: auto;
}
.patterns-upload img {
  border-radius: 15px;
  border: solid 2px #fff;
  padding: 3px;
}
.patterns-upload img:hover {
  border: solid 2px #bc6982;
}
.sketch-picker div:nth-child(1) {
  padding-bottom: 0 !important;
}
.save-name button {
  background-color: #ff6600;
  color: #fdfdfd;
  border-radius: 50px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.16);
  padding: 8px 60px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
}
.color-selection {
  p {
    width: 20px;
    height: 20px;
    border-radius: 50px;
    display: inline-block;
    margin: 10px 4px;
  }
}
.grdient-color {
  width: 300px;
  height: 209px;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 9, 121, 1) 35%,
    rgba(0, 212, 255, 1) 100%
  );
  margin-right: 10px;
  margin-left: 10px;
}
.gradient-stick {
  width: 36.2px;
  height: 210.1px;
  background-color: red;
  background-image: linear-gradient(
    to right,
    red,
    #f06d06,
    rgb(255, 255, 0),
    green
  );
}
.color-dots {
  border-right: solid 1px #dedede;
}
.delete-color {
  color: #ff0000;
}
.timeline-content {
  font-family: "HelveticaNeue";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 26px;
  letter-spacing: normal;
  text-align: left;
  padding-left: 20px;
  color: #262626;
}
.timelinesection {
  // width: 10%;
  padding-top: 25px;
  padding-left: 15px;
  margin-left: auto;
}
.dark .timelinesection {
  filter: invert(77%) sepia(2%) saturate(815%) hue-rotate(176deg)
    brightness(95%) contrast(98%);
}
.dark .timeline-content {
  color: #ececec;
}
.savecardbutton-context {
  font-family: "HelveticaNeue";
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 18px;
  letter-spacing: normal;
  text-align: center;
  color: #fdfdfd;
}
.buttoncardpadding-content {
  width: 160px;
  height: 34px;
  padding-top: 4px;
  padding-bottom: 6px;
  margin-right: 12px;
  border-radius: 12px;
}
.filescardbutton-context {
  font-family: "HelveticaNeue";
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 18px;
  letter-spacing: normal;
  text-align: center;
  color: #ff6600;
}
.buttonfilespadding-content {
  width: 83px;
  height: 32px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-left: 16px;
  border-radius: 16px;
  border: solid 1px #ff6600;
}
.buttonbookpadding-content {
  width: 132px;
  height: 32px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-left: 16px;
  border-radius: 16px;
  border: solid 1px #ff6600;
}
.cardsjustify-end {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.fileSub-imgcontent {
  width: 44px;
  height: 44px;
  margin-top: -20%;
  margin-right: -27px;
  // box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.16);
  // box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.16);
  z-index: 2;
  position: fixed;
  cursor: pointer;
}
@media (min-width: 768px){
  .subBucketsHolderInbox .fileSub-imgcontent {
    width: 44px;
    height: 44px;
    margin-top: 14%;
    margin-right: -27px;
    // box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.16);
    // box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.16);
    z-index: 2;
    position: fixed;
    cursor: pointer;
  }
}
@media (max-width: 767px){
  .subBucketsHolderInbox .fileSub-imgcontent {
    width: 44px;
    height: 44px;
    margin-top: 51%;
    margin-right: -9px;
    z-index: 2;
    position: fixed;
    cursor: pointer;
  }
}

.filesub-bgcontent {
  background-color: #fff;
  border-radius: 18px 0px 0px 18px;
}
.dark .filesub-bgcontent {
  // background-color: rgba(255, 246, 240, 0.15);
  background-color: #051534;
  border-radius: 18px 0px 0px 18px;
}
// .bookmarksSub-bgcontent{
//     background-color: #fff;
//     border-radius: 11px 0px 0px 11px;
// }
// .dark .bookmarksSub-bgcontent{
//     background-color: rgba(255, 246, 240, 0.15);
//     border-radius: 11px 0px 0px 11px;
// }
.bookmarksSub-imgcontent {
  width: 44px;
  height: 44px;
  margin-top: -13%;
  margin-right: -27px;
  // box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.16);
  z-index: 2;
  position: fixed;
  cursor: pointer;
}
@media (min-width: 768px){
  .subBucketsHolderInbox .bookmarksSub-imgcontent{
    width: 44px;
    height: 44px;
    margin-top: 21%;
    margin-right: -27px;
    // box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.16);
    z-index: 2;
    position: fixed;
    cursor: pointer;
  }
}
@media (max-width: 767px){
  .subBucketsHolderInbox .bookmarksSub-imgcontent{
    width: 44px;
    height: 44px;
    margin-top: 78%;
    margin-right: -8px;
    z-index: 2;
    position: fixed;
    cursor: pointer;
  }
}
.fileswhole-section {
  padding-top: 26px;
  padding-bottom: 18px;
}
.file-section {
  width: 29%;
  align-items: center;
  margin-right: 10px;
}
.Bookmarklist .file-section {
  width: 27%;
  align-items: center;
  margin-right: 10px;
}
.bucket-section {
  width: 24%;
  align-items: center;
  margin-right: 10px;
}
.upload-section {
  width: 13%;
  align-items: center;
  margin-right: 10px;
}
.Bookmarklist .upload-section {
  width: 17%;
  align-items: center;
  margin-right: 10px;
}
.sharedwith-section {
  width: 15%;
  align-items: center;
}
.size-section {
  width: 15%;
  align-items: center;
}
.action-section {
  width: 12%;
  align-items: center;
}
.spacefiles-content {
  padding-top: 26px;
}
.tooltipfile .tooltip-text.fileshrename {
  display: none;
  width: 130%;
  text-align: center;
  height: 44px;
  transform: none;
  left: 0;
}
.tooltipfile span {
  transition: opacity 0.3s;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-inline-box;
}
.tooltipfile .tooltip-text {
  font-family: "HelveticaNeue";
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  visibility: visible;
  // width: 250px;
  background-color: #000;
  color: #fff;
  // text-align: left;
  border-radius: 6px;
  padding: 4px;
  position: absolute;
  z-index: 1;
  // display: none;
  bottom: 30px;
  // left: 103px;
  margin-left: 5px;
  // transform: translateX(-50%);
  /* display: none; */
  transition: opacity 0.3s;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
// .profilecard-imgcontent {
//     display: flex;
//     margin-bottom: 2px;
// }
// .profilecard-imgcontent img {
//     width: 30px;
//     height: 30px;
// }
// .profilecard-imgcontent img:nth-child(2){
//     margin-left: -9px;
// }
// .profilecard-imgcontent img:nth-child(3){
//     margin-left: -12px;
// }
.react-tabs__tab-panel {
  form {
    width: fit-content;
    border: 1px solid #d8d8d8;
    border-radius: 20px;
  }
  input {
    padding-left: 12px;
  }
  input::placeholder {
    font-size: 16px;
  }
  .search-color {
    input {
      width: 162px;
      padding: 7px 3px 7px 12px;
      border-radius: 20px;
    }
    button {
      background: #ff6600;
      color: #fdfdfd;
      font-family: "HelveticaNeue";
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      padding: 8px 13px;
      border-radius: 50px;
    }
  }
}
.epr-emoji-category-content button {
  border: 1px solid #d8d8d8 !important;
  border-radius: 0 !important;
  margin-right: 10px;
}
.epr-emoji-category-content button:hover {
  border: 1px solid #ff6600 !important;
}
.EmojiPickerReact button.epr-emoji {
  max-width: unset !important;
  max-height: unset !important;
  width: 43px !important;
  height: 45px !important;
  margin-bottom: 10px;
}
.EmojiPickerReact button.epr-emoji .epr-emoji-img {
  width: 40px !important;
  height: 40px !important;
  max-width: unset !important;
  max-height: unset !important;
}
.epr-emoji-category-content button {
  width: 60px;
  height: 60px;
}
.EmojiPickerReact li.epr-emoji-category > .epr-emoji-category-content {
  grid-template-columns: repeat(7, 54px) !important;
}
.EmojiPickerReact button.epr-emoji:hover > * {
  background-color: #fff !important;
}
.bgimage-upload .grid {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 7px;
  grid-row-gap: 8px;
}
.bgimage-upload .grid div {
  width: 158px;
  height: auto;
}
.icon-upload .grid {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  grid-row-gap: 10px;
}
.icon-upload .grid img {
  height: 40px;
  width: 40px;
}
.icon-upload .grid div {
  border: 1px solid #d8d8d8;
  // padding: 15px;
  width: 45px;
  height: 45px;
  text-align: -webkit-center;
  display: flex;
}
.icon-upload .grid div > img {
  margin: auto;
}
.popup-upload {
  border-radius: 15px;
  .right {
    padding: 20px 38px;
    border-radius: 0 15px 15px 0;
    .card {
      width: 250px;
      height: 190px;
      padding: 8px 12px;
      background-color: #fff;
      border: 1px solid #e5e5e5;
      filter: drop-shadow(0px 9.67123px 14.5068px rgba(0, 0, 0, 0.161));
      border-radius: 16px;
      margin: 0 auto;
    }
  }
  .customize-bucket-tabs {
    .react-tabs {
      .react-tabs__tab-panel {
        .color-upload,
        .icon-upload {
          max-height: 300px;
          overflow: auto;
        }
      }
    }
  }
}
.customize-bucket-tabs {
  .react-tabs {
    padding-bottom: 70px;
  }
}
.upload-container {
  padding: 10px;
  // background: #982402;
  height: 100px;
  border-radius: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: center;
  background-size: 100% !important;
  background-position: center !important;
}
.popuptext-background {
  font-family: "HelveticaNeue";
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  padding: 4px 10px 4px 10px;
  text-align: left;
  display: inline;
  border-radius: 20px;
  color: #fff;
  background-color: #000;
  display: -webkit-inline-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.profile img {
  width: 25px;
  display: flex;
}
.profile img:nth-child(2) {
  margin-left: -14px;
}
.profile img:nth-child(3) {
  margin-left: -18px;
}
.filespace-content {
  padding-left: 34px;
}
.fileheading-content {
  font-family: "HelveticaNeue";
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: normal;
  text-align: left;
  color: #262626;
}
.filesection-content {
  font-family: "HelveticaNeue";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 26px;
  letter-spacing: normal;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 310px;
  color: #262626;
}
.bucket-title {
  // display: -webkit-inline-box;
  // -webkit-line-clamp: 1;
  // -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: pointer;
  text-overflow: ellipsis;
  max-width: 262px;
  white-space: nowrap;
}
#default-checkbox {
  width: 20px;
  height: 20px;
}
.dark .sharedwith-section {
  color: #fff;
}
.dark #default-checkbox {
  filter: invert(100%) sepia(5%) saturate(72%) hue-rotate(59deg)
    brightness(116%) contrast(78%);
}
.dark .fileheading-content {
  color: #fff;
}
.dark .filesection-content {
  color: #fff;
}
// .dark .action-section img {
//   filter: invert(84%) sepia(1%) saturate(175%) hue-rotate(314deg)
//     brightness(93%) contrast(84%);
// }
.dark .threedots-content {
  filter: invert(84%) sepia(1%) saturate(175%) hue-rotate(314deg)
    brightness(93%) contrast(84%);
}
.dark .bucket-title {
  color: #fff;
}
.filescard-section {
  // padding-top: 26px;
  // margin-bottom: 30px;
  grid-gap: 15px;
  grid-row-gap: 10px;
  .cardHolder-cards {
    width: 313px;
    border-radius: 14px;
    padding: 5px 15px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #f5f5f5;
  }
  .linkbookmark-content {
    max-width: 180px;
    padding-top: 21px;
    // display: -webkit-inline-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .bookmarkiconstar-content {
    margin-right: 55px;
  }
  .clipicon-content {
    padding-top: 8px;
  }
  .linkbookmarksingle-content {
    padding-top: 3px;
  }
  .bookmarksingledot-content {
    padding-top: 8px;
  }
  .design-textcontent {
    font-family: "HelveticaNeue";
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: normal;
    text-align: left;
    padding-top: 8px;
    max-width: 180px;
    display: -webkit-inline-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #262626;
  }
  .Cardtitle {
    align-items: center;
  }
  .iconsalign-content {
    margin-left: auto;
  }
  .cardtext-content {
    font-family: "HelveticaNeue";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: left;
    padding-top: 6px;
    padding-bottom: 14px;
    color: #141414;
  }
  .fashioncards-content {
    font-family: "HelveticaNeue";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 22px;
    letter-spacing: normal;
    text-align: left;
    color: #262626;
  }
  .uploadedcards-content {
    font-family: "HelveticaNeue";
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 22px;
    letter-spacing: normal;
    text-align: left;
    color: #484848;
  }
  .sharesright-content {
    font-family: "HelveticaNeue";
    font-size: 10px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: right;
    margin-top: 4px;
    color: #000;
  }
  .profile-displaycontent {
    margin-top: unset;
    margin-left: auto;
  }
  .cardsicon-content {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.dark .filescard-section .cardHolder-cards {
  border: solid 1px #4c618f;
  background-color: unset;
}
.dark .filescard-section .design-textcontent {
  color: #fff;
}
.dark .filescard-section .linkcards-icon {
  filter: invert(100%) sepia(5%) saturate(72%) hue-rotate(59deg)
    brightness(116%) contrast(78%);
}
.dark .filescard-section .sharesright-content {
  color: #d3d3d3;
}
.dark .filescard-section .fashioncards-content {
  color: #bbb;
}
.dark .filescard-section .uploadedcards-content {
  color: #bbb;
}
.dark .inputCheck input {
  filter: invert(100%) sepia(5%) saturate(72%) hue-rotate(59deg)
    brightness(116%) contrast(78%);
}
.subbucketsbg-content {
  padding: 1px 12px;
}
.dark .bookmarksGrid-wholesection .cardssubBuckets-content {
  color: #ececec;
}
.dark .cardssubBuckets-content {
  color: #ececec;
}
.update-history {
  h6 {
    font-family: "Inter-Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 22.0677px;
    line-height: 27px;
    /* identical to box height */

    color: #262626;
  }
}
.color-upload {
  .colors {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 6px;
    grid-row-gap: 15px;
  }
}
.image-upload {
  width: 200px;
  height: 100px;
  background: linear-gradient(0deg, #ff6600, #ff6600);
  // border: 1px solid #D8D8D8;
  img {
    height: 30px;
  }
  h6 {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
  }
  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 11px;
    text-align: center;
    color: #ffffff;
  }
}
.image-upload {
  img {
    margin: auto;
  }
}
aside.EmojiPickerReact.epr-main {
  height: 300px !important;
  overflow: hidden;
}
.react-tabs__tab-panel::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}
.react-tabs__tab-panel::-webkit-scrollbar-thumb {
  background: #c8c7c8;
  height: 80px;
  border-radius: 10px;
}
.react-tabs__tab-panel::-webkit-scrollbar {
  width: 8px;
  height: 30px;
  border-radius: 10px !important;
}
.EmojiPickerReact .epr-body::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}
.EmojiPickerReact .epr-body::-webkit-scrollbar-thumb {
  background: #c8c7c8;
  height: 80px;
  border-radius: 10px;
}
.EmojiPickerReact .epr-body::-webkit-scrollbar {
  width: 8px;
  height: 30px;
  border-radius: 10px !important;
}
.upload-container .selectedupload-content {
  width: 40px;
  height: 40px;
}
.change-histoty p {
  color: #707070;
  font-size: 15px;
}
//   .popup-upload {
// .react-tabs ul li{
//     justify-content: space-between;
// }
//   }

.color-upload {
  .react-tabs__tab {
    display: inline;
  }
}
.EmojiPickerReact li.epr-emoji-category > .epr-emoji-category-content {
  margin: 0 !important;
}
p {
  font-family: "HelveticaNeue";
}
.dark {
  .epr-emoji-category-content {
    background: #0c2556;
  }
  .popup-upload {
    background: #0c2556;
    p {
      color: #c7c7c7;
      opacity: 1;
    }
    h3 {
      color: #fff;
      background-color: #0c2556;
    }
    .update-history {
      h6 {
        color: #fff;
      }
    }
    .react-tabs__tab {
      color: #fff;

      img {
        filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(120deg)
          brightness(107%) contrast(101%);
      }
    }
    .react-tabs__tab--selected {
      background: #0c2556;
    }
    .react-tabs {
      ul {
        border-bottom: 1px solid #202f4f;
      }
    }
    .save-name {
      background: #0c2556;
    }
    .image-upload {
      p {
        color: #fff;
      }
    }
  }
  .right {
    .card {
      background: #0b2c6f;
      border: solid 1px #09255d;
    }
  }
  .change-histoty {
    .close-history {
      filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(120deg)
        brightness(107%) contrast(101%);
    }
  }
}
.dark {
  .dark-icon-clr {
    filter: invert(77%) sepia(2%) saturate(815%) hue-rotate(176deg)
      brightness(95%) contrast(98%);
  }
}
.cardsspacing-content {
  margin-bottom: 20px;
}
.cardtextwhole-content .cardDetailContent {
  font-family: "HelveticaNeue";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 22px;
  letter-spacing: normal;
  text-align: left;
  color: #262626;
  width: 100%;
  // padding-left: 7px;
  // padding-right: 7px;
  margin-top: -4px;
  // -webkit-line-clamp: 5;
  // -webkit-box-orient: vertical;
  overflow: hidden;
  // display: -webkit-inline-box;
}
.publicshare-section .cardtextwhole-content .cardDetailContent{
  font-family: "HelveticaNeue";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 22px;
  letter-spacing: normal;
  text-align: left;
  color: #262626;
  width: 100%;
  // padding-left: 7px;
  // padding-right: 7px;
  margin-top: -4px;
  -webkit-line-clamp: unset !important;
  -webkit-box-orient: unset !important;
  overflow: unset !important;
  display: unset !important;
}
.dark .publicshare-section .cardtextwhole-content .cardDetailContent{
  color: #bbb;
}
.dark .cardDetailContent {
  color: #bbb;
}
.dark .cardListContent {
  color: #bbb;
  // padding-left: 7px;
  // padding-right: 7px;
}
// .dark .cardListContent span {
//   font-size: 14px !important;
//   font-weight: bold !important;
//   color: #bbb !important;
// }
.cardListContent b {
  font-size: 14px !important;
  font-weight: normal !important;
  color: #262626 !important;
}
.dark .cardListContent b {
  font-size: 14px !important;
  font-weight: bold !important;
  color: #bbb !important;
}
.cardListContent span.css-1jxf684.r-bcqeeo.r-1ttztb7.r-qvutc0.r-poiln3 {
  // -webkit-line-clamp: 3;
  // -webkit-box-orient: vertical;
  // display: -webkit-inline-box;
}
.cardListContent span {
  // display: -webkit-inline-box;
  // -webkit-line-clamp: 3;
  // -webkit-box-orient: vertical;
  // overflow: hidden;
  font-size: 14px !important;
  font-weight: normal !important;
  color: #262626 !important;
}
.cardListContent .xt0psk2 span{
  display: unset;
  -webkit-line-clamp: unset;
  -webkit-box-orient: unset;
  // overflow: unset;
  font-size: 14px !important;
  font-weight: normal !important;
  color: #262626 !important;
}
.cardListContent .css-1qaijid.r-bcqeeo.r-qvutc0.r-poiln3{
  padding-left: 14px;
  padding-right: 14px;
}
.dark .cardListContent span{
  text-align: left !important;
}
.dark .cardListContent span{
  color: #bbb !important;
}
.cardListContent .r-xoduu5 {
  display: none;
}
.cardlist-content span .r-qvutc0:nth-child(2) {
  display: none;
}
.cardListContent span:nth-child(2) {
  // display: none;
}
.cardListContent span:nth-child(3) {
  display: none;
}
// .subBuckets-whole .cardHolder .cardListContent .div span:nth-child(1){
//   display: -webkit-inline-box;
//   -webkit-line-clamp: 3;
//   -webkit-box-orient: vertical;
//   overflow: hidden;
//   font-size: 14px !important;
//   font-weight: normal !important;
//   color: #262626 !important;
// }
.tagspace-notecontent {
  padding-top: 30px;
  padding-bottom: unset;
}
.landing-imgcontent {
  width: 174px;
}
.landing-cardcontent {
  // margin-left: 16px;
}
.dark .purple-imgcontent {
  color: #78c3ff;
}
.landingpage-content {
  font-family: "HelveticaNeue";
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.83;
  letter-spacing: normal;
  text-align: left;
  padding-bottom: 16px;
  padding-top: 30px;
  color: #262626;
}
.purple-imgcontent {
  font-family: "HelveticaNeue";
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  display: flex;
  padding-top: 16px;
  // text-decoration: underline;
  color: #5773d1;
}
.dark .landingpage-content {
  color: #fff;
}
.dark .purple-imgcontent img {
  filter: invert(77%) sepia(2%) saturate(815%) hue-rotate(176deg)
    brightness(95%) contrast(98%);
}
.filesuploadpopup {
  position: fixed;
  overflow: scroll;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  display: flex;
  transition: left 0.3s ease-in-out;
}
.subfilesupload-section {
  position: fixed;
  overflow: scroll;
  top: 10%;
  left: 36%;
  border-radius: 10px;
  z-index: 1;
  padding: 22px 15px 0px 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  background: #fff;
  width: 30%;
  // height: 80%;
  margin: 0 auto;
  transition: left 0.3s ease-in-out;
}
.filesuploadpopup::-webkit-scrollbar {
  width: 0;
}
.filesuploadpopup ::-webkit-scrollbar-track {
  background: transparent;
}
.filesuploadpopup::-webkit-scrollbar-thumb {
  background: transparent;
}
.subfilesupload-section::-webkit-scrollbar {
  width: 0;
}
.subfilesupload-section ::-webkit-scrollbar-track {
  background: transparent;
}
.subfilesupload-section::-webkit-scrollbar-thumb {
  background: transparent;
}
.fileupload-section {
  padding-bottom: 10px;
}
.fileupload-content {
  font-family: "Objectivity-Medium";
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
.dismissSubfiles-content {
  margin-left: auto;
  margin-bottom: 10px;
  cursor: pointer;
}
.uploadtext-content {
  font-family: "HelveticaNeue";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  padding-top: 14px;
  color: #262626;
}
.menusubitems-content {
  justify-content: space-between;
  padding: 4px 14px;
  margin-top: 14px;
  margin-bottom: 24px;
  border: solid 1px #dedede;
  background-color: #f5f5f5;
}
.createdsubtext-content {
  font-family: "HelveticaNeue";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  line-height: 26px;
  letter-spacing: normal;
  text-align: left;
  // padding-right: 20px;
  color: #707070;
}
.addnotes-content {
  height: 130px;
  padding: 4px 14px;
  margin-bottom: 24px;
  border-radius: 16px;
  border: solid 1px #dedede;
  background-color: #f5f5f5;
}
.browser-content {
  height: 130px;
  border-radius: 16px;
  border: solid 1px rgba(112, 112, 112, 0.4);
  border-style: dashed;
  background-color: #f5f5f5;
}
.uploadimg-content {
  margin: 0 auto;
  display: flex;
  padding-top: 30px;
  padding-bottom: 10px;
}
.drag-content {
  font-family: "HelveticaNeue";
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #707070;
}
.browse-content {
  color: #8276b4;
}
.savebutton-context {
  font-family: "HelveticaNeue";
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: normal;
  text-align: center;
  padding: 6px 30px;
  color: #fdfdfd;
}
.lastfile-section {
  padding-top: 20px;
}
.cancel-content {
  font-family: "HelveticaNeue";
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 36px;
  letter-spacing: normal;
  text-align: center;
  padding-right: 30px;
  color: #8276b4;
}
.bookmarksfilesuploadpopup {
  position: fixed;
  overflow: scroll;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  display: flex;
  transition: left 0.3s ease-in-out;
}
.bookmarkssubfilesupload-section {
  position: fixed;
  overflow: scroll;
  top: 15%;
  left: 36%;
  border-radius: 10px;
  z-index: 1;
  padding: 22px 15px 0px 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  background: #fff;
  width: 30%;
  // height: 70%;
  margin: 0 auto;
  transition: left 0.3s ease-in-out;
}
.bucketCreationPopuptags {
  position: fixed;
  /* overflow: scroll; */
  top: 0;
  left: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bucketCreationPopuptags form {
  // height: 36%;
  position: fixed;
  top: 30%;
  border-radius: 10px !important;
  padding: 18px;
  margin-top: 18px;
  margin-bottom: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  background: #fff;
  display: flex;
  flex-direction: column;
}
.tab-scrolls ul {
  overflow-y: scroll;
  overflow-x: hidden;
}
.tab-scrolls ul::-webkit-scrollbar {
  width: 10px;
  height: 400px;
}
.tab-scrolls ul::-webkit-scrollbar-track {
  background-color: transparent;
}
.tab-scrolls ul::-webkit-scrollbar-thumb {
  background-color: #c6c6c6;
  border-radius: 8px;
}
ul.dropdown {
  /* max-height: 50vh; 
    overflow: auto; */
  max-height: 418px;
  top: 100%;
  position: absolute;
  // top: 42%;
  left: 0;
  right: 0;
  z-index: 11;
  // background-color: #fff;
  border-radius: 10px;
  padding: 10px 0;
  text-align: left;
  // box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.16);
}
.singlecard {
  top: 36px;
}
.filescard {
  top: 10%;
}
.filescard ul.dropdown {
  /* max-height: 50vh;
    overflow: auto; */
  // max-width: 480px;
  max-height: 298px;
  position: absolute;
  top: 38px;
  left: -15px;
  right: -15px;
  z-index: 11;
  background-color: #fff;
  border-radius: 10px;
  // border: 1px solid #707070;
  padding: 12px 0px;
  text-align: left;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.16);
}
.filescard .dropdown li ul {
  // position: absolute;
  // top: 48px;
  // right: 0px;
  // left: 40px;
  // width: 386px;
  // background-color: #fff;
  // border-radius: 5px;
  // border: solid 1px #d9d9d9;
  // padding: 4px;
  // z-index: 999;
  // margin-left: 10px;
  // margin-right: 10px;
  /* box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3); */
  // border-radius: 10px;
  // overflow: hidden;
  // box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.16);
  /* margin-left: auto; */

  font-weight: 400px !important;
  position: static;
  top: 24px;
  right: 0px;
  left: -11px;
  width: 100%;
  background-color: unset;
  border: unset;
  padding: 0px 0px;
  z-index: unset;
  margin-right: 10px;

  border-radius: unset;
  overflow: hidden;
  box-shadow: unset;
}
.tagscard ul.dropdown {
  /* max-height: 50vh;
    overflow: auto; */
  max-height: 270px;
  position: absolute;
  top: 37%;
  left: 14px;
  right: 14px;
  z-index: 11;
  background-color: #fff;
  border-radius: 10px;
  // border: 1px solid #707070;
  padding: 10px 0;
  text-align: left;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.16);
}
.tagscard .dropdown li ul {
  position: absolute;
  top: 48px;
  right: 0px;
  left: 40px;
  width: 386px;
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #d9d9d9;
  padding: 4px;
  z-index: 999;
  margin-left: 10px;
  margin-right: 10px;
  /* box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3); */
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.16);
  /* margin-left: auto; */
}
.inputspaces-content {
  margin-bottom: 30px;
}
.bookmarksfilesuploadpopup::-webkit-scrollbar {
  width: 0;
}
.bookmarksfilesuploadpopup ::-webkit-scrollbar-track {
  background: transparent;
}
.bookmarksfilesuploadpopup::-webkit-scrollbar-thumb {
  background: transparent;
}
.bookmarkssubfilesupload-section::-webkit-scrollbar {
  width: 0;
}
.bookmarkssubfilesupload-section ::-webkit-scrollbar-track {
  background: transparent;
}
.bookmarkssubfilesupload-section::-webkit-scrollbar-thumb {
  background: transparent;
}
.bookmarkspopup-content {
  width: auto;
  max-height: 700px;
  // padding-top: 4%;
  padding-bottom: 18px;
  margin: 0 auto;
}
.mblicon-content {
  width: unset;
}
.subbucketheadermbl-section {
  display: none;
}
.subbucketheadernotes-section {
  display: block;
}
.carddetail-desktopsection {
  display: block;
}
.carddetail-mblsection {
  display: none;
}
.buckerinnerheaderdesktop-section {
  display: block;
}
.bucketinnerheadermbl-section {
  display: none;
}
.myfiles-content {
  font-family: "Objectivity-Medium";
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  line-height: 42px;
  letter-spacing: -1.4px;
  text-align: left;
  margin-right: 18px;
  color: #262626;
}
.mybookmark-content {
  font-family: "Objectivity-Medium";
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  line-height: 42px;
  letter-spacing: -1.4px;
  text-align: left;
  margin-right: 18px;
  color: #262626;
}
.bookmarksinnerheaderdesktop-section {
  display: block;
}
.bookmarksinnerheadermbl-section {
  display: none;
}
.dropdowntrans-content {
  margin-top: -7px;
  z-index: 9999;
}
.dropdownbucket-file {
  // z-index: 9;
}
.filesbucketuploadpopup {
  // z-index: 999;
  position: fixed;
  overflow: scroll;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  display: flex;
  transition: left 0.3s ease-in-out;
}
.subfilesbucketupload-section {
  position: fixed;
  overflow: scroll;
  top: 13%;
  left: 20%;
  border-radius: 10px;
  // z-index: 1;
  padding: 22px 15px 0px 15px;
  // box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  // background: #fff;
  width: 61%;
  // height: 74%;
  margin: 0 auto;
  transition: left 0.3s ease-in-out;
}
.filesbucketuploadpopup::-webkit-scrollbar {
  width: 0;
}
.filesbucketuploadpopup ::-webkit-scrollbar-track {
  background: transparent;
}
.filesbucketuploadpopup::-webkit-scrollbar-thumb {
  background: transparent;
}
.subfilesbucketupload-section::-webkit-scrollbar {
  width: 0;
}
.subfilesbucketupload-section ::-webkit-scrollbar-track {
  background: transparent;
}
.subfilesbucketupload-section::-webkit-scrollbar-thumb {
  background: transparent;
}
.dragsection-content {
  // z-index: 1;
}
.selectedicon-content {
  padding-top: 3px;
  padding-left: 6px;
}
.selectedemoji-content {
  padding-left: 6px;
}
.dark .popup-upload .react-tabs__tab--selected {
  background: #2f436a;
  border-color: transparent;
  border-bottom: unset !important;
}
.dark .carddetailfile-content {
  color: #ffffff;
  background-color: unset;
}
// .cardcustomize-section{
//     z-index: 999;
// }
// .mansory-section div:nth-child(1){
//    display: flex;
// }
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .cardHolder {
    width: auto;
  }
}
@media only screen and (min-width: 1900px) and (max-width: 2200px) {
  .cardHolder {
    width: auto;
  }
}

@media (min-width: 320px) and (max-width: 771px) {
  .mybuckets-content {
    font-family: "Objectivity-Medium";
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    line-height: 42px;
    letter-spacing: -1.4px;
    text-align: left;
    margin-right: 18px;
    color: #262626;
  }
  .subfilesbucketupload-section{
    width: 100% !important;
    left: unset !important;
  }
  .customize .save-name button{
    padding: unset !important;
  }
  .carddetail .secondsection-content{
    display: inline;
    padding-top: unset;
  }
  .carddetail .cardDetailHolder{
    padding-left: 8px;
    padding-right: 8px;
  }
  .cardsspacing-content {
    margin-left: 10px;
    margin-right: 10px;
  }
  .BucketListHolder-content .subinviteupload-section{
    left: 5%;
    width: unset;
  }
  .cardsspacing-content .sortmbl-imgcontent{
    margin-top: 22px;
  }
  .subbucketheadermbl-section {
    display: block;
  }
  .subbucketheadernotes-section {
    display: none;
  }
  .carddetail-desktopsection .cards{
    // display: none;
  }
  .carddetail-mblsection {
    display: block;
  }
  .buckerinnerheaderdesktop-section {
    display: none;
  }
  .bucketinnerheadermbl-section {
    display: block;
  }
  .bookmarksinnerheaderdesktop-section {
    display: none;
  }
  .bookmarksinnerheadermbl-section {
    display: block;
  }
  .filescard-section .cardHolder-cards {
    width: 353px;
    //     border-radius: 14px;
    // padding: 5px 15px;
    // box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    // background-color: #f5f5f5;
    // border: solid 1px #e5e5e5;
  }
  .myfiles-content {
    font-family: "Objectivity-Medium";
    font-size: 13px;
    font-weight: bold;
    font-style: normal;
    line-height: 42px;
    letter-spacing: -1.4px;
    text-align: left;
    margin-right: 18px;
    color: #262626;
  }
  // .mybookmark-content{
  //     font-family: "Objectivity-Medium";
  //     font-size: 13px;
  //     font-weight: bold;
  //     font-style: normal;
  //     line-height: 42px;
  //     letter-spacing: -1.4px;
  //     text-align: left;
  //     margin-right: 18px;
  //     color: #262626;
  // }
  .totalbucket-content {
    font-family: "HelveticaNeue";
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    color: #707070;
  }
  .buttonpadding-content {
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .dropdownmbl-content {
    margin-top: 14px;
    // margin-right: -190%;
  }
  .subBuckets-whole .subBucket-content {
    font-family: "HelveticaNeue";
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #8276b4;
  }
  .subBuckets-whole .bucketActions {
    padding-left: 92px;
  }
  .subBuckets-whole .bucketName-content {
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -1.4px;
    text-align: left;
    margin-bottom: 10px;
    margin-top: 16px;
    color: #262626;
    font-family: "Objectivity";
  }
  .fileSub-imgcontent {
    width: 44px;
    height: 44px;
    margin-top: -86%;
    margin-right: -10px;
    z-index: 2;
    position: fixed;
    cursor: pointer;
  }
  .bookmarksSub-imgcontent {
    width: 44px;
    height: 44px;
    margin-top: -58%;
    margin-right: -10px;
    z-index: 2;
    position: fixed;
    cursor: pointer;
  }
  .mblicon-content {
    width: 20px;
  }
  .btn-invited {
    font-family: "HelveticaNeue";
    font-size: 14px;
    font-weight: 500;
    margin-top: 4px;
    margin-bottom: 9px;
    font-style: normal;
    padding: 4px 10px;
  }
  .subBuckets-whole .subBucketsList .BucketListHolder-content .bucketSingle {
    width: 172px;
    height: unset;
    z-index: unset;
    position: relative;
    box-shadow: 0 6px 9px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #e5e5e5;
    background-color: #fff;
    border-radius: 16px;
  }
  .BucketListHolder-content {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;
    grid-row-gap: 12px;
  }
  .cardHolder {
    width: auto;
    margin-bottom: 10px;
    border: 1px solid #655e5e;
  }
}

.epr-emoji-category-content {
  display: flex !important;
  flex-wrap: wrap;
  padding: unset !important;
  justify-content: flex-start !important;
}

.epr-emoji-category-content button.epr-btn {
  width: 45px !important;
  height: 45px !important;
  border: 1px solid #d8d8d8 !important;
  margin-right: 10px !important;
}
.epr-emoji-category-content button {
  width: 60px;
  height: 60px;
}

.epr-emoji-category-content button.epr-btn img {
  padding: 5px !important;
  object-fit: contain;
}

.history-list-item {
  display: flex;
  align-items: center;
  padding-top: 14px;
}

.history-list-item p {
  color: #707070;
  font-size: 14px;
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.history-list-item img.close-history {
  margin-left: 7px;
  width: 10px;
  height: 10px;
  object-fit: contain;
  cursor: pointer;
}

.history-list-item svg {
  width: 18px;
  color: #838383;
  display: inline-block;
  margin-left: 7px;
}
.history-list-item span {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-left: 7px;
}
.history-list-item small {
  display: inline-block;
  font-size: 17px;
  margin-left: 7px;
}
.popup-upload .color-upload .swatches-picker {
  position: relative;
}
// .popup-upload .color-upload .swatches-picker::after{
//     content: "";
//     background: #fff;
//     height: 100%;
//     display: block;
//     width: 15px;
//     position: absolute;
//     right: -2px;
//     top: 0;
//     z-index: 1;
// }
// .popup-upload .color-upload .swatches-picker::before{
//     content: "";
//     background: #fff;
//     height: 15px;
//     display: block;
//     width: 100%;
//     position: absolute;
//     left: 0;
//     right: 0;
//     bottom: -2px;
//     z-index: 1;
// }
.color-upload .swatches-picker div {
  height: auto !important;
  padding: 0 !important;
}

.color-upload .swatches-picker div span > div div {
  height: auto !important;
  padding: 0 !important;
  overflow: auto !important;
  margin: 0 0 !important;
}

.color-upload .swatches-picker div span > div {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 8px !important;
  // width: 45px !important;
  height: 40px !important;
  background-size: contain !important;
  overflow: hidden !important;
  margin-bottom: 12px !important;
}
.color-upload .swatches-picker div span {
  display: block;
  width: 100%;
}
.color-upload .swatches-picker div span div svg {
  width: auto !important;
  height: auto !important;
}
.filesuploadpopup {
  position: fixed;
  overflow: scroll;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  display: flex;
  transition: left 0.3s ease-in-out;
}
.filesuploadpopup::-webkit-scrollbar {
  width: 0;
}
.filesuploadpopup ::-webkit-scrollbar-track {
  background: transparent;
}
.filesuploadpopup::-webkit-scrollbar-thumb {
  background: transparent;
}
.subinviteupload-section {
  position: fixed;
  overflow: scroll;
  top: 10%;
  left: 36%;
  border-radius: 10px;
  z-index: 1;
  padding: 22px 15px 0px 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  background: #fff;
  width: 30%;
  margin: 0 auto;
  transition: left 0.3s ease-in-out;
}
.subinviteupload-section::-webkit-scrollbar {
  width: 0;
}
.subinviteupload-section ::-webkit-scrollbar-track {
  background: transparent;
}
.subinviteupload-section::-webkit-scrollbar-thumb {
  background: transparent;
}
.teaminvitesection {
  padding-bottom: 10px;
}
.teammembers-content {
  font-family: "Objectivity-Medium";
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: #262626;
}
.teambuckets-content {
  font-family: "HelveticaNeue";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  margin-top: 14px;
  // margin-bottom: 20px;
  color: #262626;
}
.teamaccess-content {
  font-family: "HelveticaNeue";
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  padding-bottom: 10px;
  color: #262626;
  margin-top: 20px;
}
.profileteams-imgcontent {
  width: 50px;
  height: 50px;
  margin-right: 12px;
  border-radius: 50px;
}
.teamowner-content {
  font-family: "HelveticaNeue";
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: #000;
  margin-bottom: 4px;
}
.teamemail-content {
  font-family: "HelveticaNeue";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5b5b5b;
}
.shareowner-content {
  font-family: "HelveticaNeue";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
  margin-left: auto;
  color: #b9b9b9;
  margin: auto 0 auto auto;
  align-content: end;
}
.teamsavebutton-context {
  font-family: "HelveticaNeue";
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: normal;
  text-align: center;
  padding: 6px 60px;
  color: #fdfdfd;
}
.teampopupbucket-content {
  // height: 49px;
  justify-content: space-between;
  padding: 8px 14px;
  margin-top: 14px;
  // margin-bottom: 20px;
  border: solid 1px #dedede;
  background-color: #f5f5f5;
  border-radius: 22px;
  width: 320px;
}
.teampopupbucket-content input {
  width: 150% !important;
  outline: unset !important;
  background-color: unset !important;
}
.tagscardteam ul.dropdown {
  height: auto;
  position: absolute;
  top: 50%;
  left: 14px;
  right: 14px;
  z-index: 11;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 0;
  text-align: left;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.16);
}
.firstteambucket-content {
  width: 100% !important;
  // margin-right: 20px;
}
.teampopupbucket-content select {
  outline: unset !important;
  background-color: unset !important;
}
.textpopup-content {
  font-size: 15px;
  line-height: 16px;
}
.mail-imgcontent {
  width: 20px;
  height: 20px;
}
.mailbucket-imgcontent {
  height: 23px;
  width: 23px;
  margin-left: 3px;
  filter: unset;
}
.makebucket-content {
  padding-right: 3px;
}
.filescardbucket ul.dropdown {
  max-width: 480px;
  max-height: 298px;
  position: absolute;
  top: 54px;
  left: -1px;
  right: -1px;
  z-index: 11;
  background-color: #fff;
  border-radius: 10px;
  padding: 0px 0px;
  text-align: left;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.16);
}
.filescardbucket .dropdown li ul {
  /* box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3); */
  /* margin-left: auto; */
  font-weight: 400px !important;
  position: static;
  top: 24px;
  right: 0px;
  left: -11px;
  width: 100%;
  background-color: unset;
  border: unset;
  padding: 6px 0px;
  z-index: unset;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: unset;
  overflow: hidden;
  box-shadow: unset;
}
.filesbottom-input {
  margin-bottom: 26px;
}
.movetotop-content {
  margin-right: 24px;
  margin-top: 88%;
}
.cancelsinglecard-content {
  margin-top: 88%;
  font-family: HelveticaNeue;
  font-size: 18px;
  padding-top: 10px;
  margin-right: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #8276b4;
}
.movetotopcard-content {
  // display: flex;
  margin-top: 92%;
  margin-right: 24px;
  // margin-left: auto;
}
.sharebucketbutton-content {
  margin-top: 16px;
  height: 37px;
  margin-left: auto;
}
.iconsfiles-imgcontent {
  margin-right: 65px;
}
.tab-scrolls.singlecard ul.dropdown li p {
  padding-left: 40px;
  font-family: "HelveticaNeue";
  font-weight: bold;
}
.downloadbutton-content {
  font-family: "HelveticaNeue";
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.69;
  letter-spacing: normal;
  text-align: left;
  padding: 6px 14px 6px 12px;
  border-radius: 15px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #989898;
  background-color: #eaeaea;
  color: #000;
}
.downloadbtn-invite {
  font-family: "HelveticaNeue";
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  padding: 6px 14px;
  float: left;
  margin-top: 20px;
  border: solid 1px #989898;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
}
.tagname-tagcontent ::placeholder {
  color: #707070;
}
.dark .tagname-tagcontent ::placeholder {
  color: #bbbbbb;
}
.cardlisticonw-content img {
  // width: 30px !important;
  // height: 30px !important;
}
.carddetailfilebg-content {
  margin-top: 20px !important;
  background-color: #eaeaea;
}
.dark .carddetailfilebg-content {
  background-color: #b2b2b2;
}
.lastsection-subiconcards {
  border-radius: 0px 0px 14px 14px;
  background-color: #f8f8f8;
}
.dark .lastsection-subiconcards {
  border-radius: 0px 0px 14px 14px;
  background-color: #071e4a;
}
.dark .carddetail.titlebucket-content input {
  color: #ffffff;
  background-color: unset;
}
.cardContent .highlightTextItem {
  padding-left: 14px;
  padding-right: 14px;
  display: -webkit-inline-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.cancelfilecard-content {
  font-family: HelveticaNeue;
  font-size: 18px;
  padding-top: 10px;
  margin-right: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  padding-left: 20px;
  color: #8276b4;
}
.buckettagnav-content {
  width: 132px;
  right: -12px;
}
.subBucketsHolder .bucketSingle .shareCount.col-span-2 {
  height: 33px;
}
.bucketSingle .shareCount.col-span-2 {
  height: 33px;
}
.CompactView-wholesection .bucketSingle .shareCount.col-span-2 {
  height: 38px;
}
.CompactView-wholesection .pin-icon {
  padding-right: 16px;
}

.regular .pin-icon {
  margin-right: 16px;
}
.CompactView-wholesection .profile-imgcontent,
.regular .profile-imgcontent {
  margin-top: 2px;
}

.popup-upload.customize {
  .react-tabs__tab {
    border-bottom: 1px solid #fff !important;
  }
  .react-tabs__tab--selected {
    font-weight: 400;
    background: #fff6f0 !important;
    border-color: transparent;
    border-bottom: 2px solid #ff6600 !important;
  }
}
.CompactView-wholesection
  .change-histoty
  .selectedemoji-content
  .__EmojiPicker__.epr-emoji-img {
  width: 24px !important;
  height: 24px !important;
}

.change-histoty .selectedemoji-content .__EmojiPicker__.epr-emoji-img {
  width: 24px !important;
  height: 24px !important;
}

.change-histoty .selectedicon-content {
  width: 24px !important;
  height: 24px !important;
  padding-top: 0;
}
// .popup-upload .color-upload .colors div {
//   overflow-y: hidden !important;
// }
.subbucketeditname-content {
  padding: 0px !important;
}
.file-share .teamaccess-content {
  margin-top: 0;
}
.subBucketsHolder .bucketsinside svg{
  width: 16px;
  height: 16px;
}
.subBucketsHolder .bucketsinside{
  margin-top: 0;
}
.subBucketsHolder .dark-icon-clr{
  width: 16px;
  height: 16px;
}
// .cardHolder-cards .buckettitle-textcontent{
//   font-family: "HelveticaNeue";
//   font-size: 22px;
//   font-weight: bold;
//   font-style: normal;
//   line-height: 30px;
//   text-align: left;
//   color: #262626;
//   max-width: 400px;
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   cursor: pointer;
// } 